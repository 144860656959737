var metaConfig = {
  title: "Alumni Affairs & Development",
  description:
    "Alumni Affairs and Development builds lifelong relationships with alumni, parents, staff, faculty and friends of the University of Guelph.",
  author: "@cheekymonkeymedia",
  ogImage: "./src/images/uofg-cornerstone.jpg",
  ogImageAlt: "University of Guelph logo",
  // GAtrackingID: "UA-106745064-4",
  GATagID: "G-9Q4PJRRYBF",
  IGuser: "5851379098",
  homepage: "/aad-home",
  menus: ["aad-main", "aad-more-info", "aad-secondary", "aad-social-media", "footer", "aad-breadcrumbs"],
}

module.exports = metaConfig
