import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import HeroSpotlight from "../components/heroSpotlight"
import Widgets from "../components/widgets"
import { contentExists } from "../utils/ug-utils"
import classNames from "classnames"
import Breadcrumbs from "../components/breadcrumbs"
import metaConfig from "../../config/sites/aad"

const BasicPage = ({ data, pageContext }) => {
  const pageData = data.pages.edges[0].node
  const alias = pageData.path.alias
  const isHomepage = alias === metaConfig.homepage;
  const title = pageData.title
  const isFixed = pageData.field_is_fixed || false
  const hideTitle = pageData.field_hide_title || false
  const heroStyle = pageData.field_hero_style || "short-hero"
  const heroSpotlightWidth = pageData.field_hero_spotlight_width ?? 50
  const imageData = pageData.field_hero_style === "long-hero" ? data.images._long_hero_edges : data.images.edges
  const videoData = data.videos.edges
  const ogDescription = contentExists(pageData.field_metatags) ? pageData.field_metatags.og_description : null
  const ogImage = contentExists(imageData)
    ? imageData[0].node.relationships.field_media_image.localFile.publicURL
    : null
  const ogImageAlt = contentExists(imageData) ? imageData[0].node.field_media_image.alt : null

  // WidgetData contains all widgets (paragraphs) that are available - when adding a new widget, validate that the correct items are selected
  // using a comparison to __typename.  This will be paragraph__WIDGETNAME - you can pass the widgetsData variable through to your component.
  const widgetsData = contentExists(pageData.relationships.field_widgets) ? pageData.relationships.field_widgets : null
  const heroWidgetsData = contentExists(pageData.relationships.field_hero_widgets)
    ? pageData.relationships.field_hero_widgets
    : null

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "basic-page",
          "aria-label": title,
        }}
      />
      <Seo title={title} description={ogDescription} img={ogImage} imgAlt={ogImageAlt} />

      {/**** Header and Title ****/}
      <div id="rotator" className={`short-hero ${contentExists(imageData) && "image"}`}>
        <Hero isFixed={isFixed} imgData={imageData} remoteVideoData={videoData} />
        <div>
          <HeroSpotlight heroStyle={heroStyle} width={heroSpotlightWidth}>
            <h1
              className={classNames("fancy-title", {
                "visually-hidden": hideTitle,
              })}
            >
              {title}
            </h1>
            <Widgets pageData={heroWidgetsData} />
          </HeroSpotlight>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <div className="container">
          {/**** Widgets content ****/}
          {!isHomepage && <Breadcrumbs targetId={pageData.drupal_internal__nid} activeId={pageData.drupal_internal__nid} activeTitle={title} />}
          <article className="row site-content basic-page">
            <section className="col-md-12 content-area">
              <Widgets pageData={widgetsData} />
            </section>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default BasicPage

export const query = graphql`
  query ($id: String) {
    pages: allNodePage(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...Page
        }
      }
    }
    images: allMediaImage(filter: { relationships: { node__page: { elemMatch: { id: { eq: $id } } } } }) {
      edges {
        node {
          ...HeroImage
        }
      }
      _long_hero_edges: edges {
        node {
          ...LongHeroImage
        }
      }
    }
    videos: allMediaRemoteVideo(filter: { relationships: { node__page: { elemMatch: { id: { eq: $id } } } } }) {
      edges {
        node {
          ...MediaRemoteVideo
        }
      }
    }
  }
`

export const PageFragment = graphql`
  fragment Page on node__page {
    ...Node
    drupal_internal__nid
    path {
      alias
    }
    field_hide_title
    field_hero_style
    field_hero_spotlight_width
    field_is_fixed
    relationships {
      field_hero_image {
        ...Image
      }
      _card_image: field_hero_image {
        ...CardImage
      }
      field_widgets {
        __typename
        ...ParagraphSectionTabs
        ...ParagraphCardCollection
        ...ParagraphCallToAction
        ...ParagraphChartWidget
        ...ParagraphGeneralText
        ...ParagraphLeadParagraph
        ...ParagraphLinksWidget
        ...ParagraphSection
        ...ParagraphMediaText
        ...ParagraphStatsWidget
        ...ParagraphShareLinks
        ...ParagraphPlaybackButtonWidget
        ...ParagraphSideBySideTextImage
      }
      field_hero_widgets {
        __typename
        ...ParagraphCallToAction
        ...ParagraphGeneralText
        ...ParagraphLeadParagraph
        ...ParagraphLinksWidget
        ...ParagraphSection
        ...ParagraphMediaText
        ...ParagraphStatsWidget
        ...ParagraphPlaybackButtonWidget
        ...ParagraphSectionButtons
      }
    }
  }
`
